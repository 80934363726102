$(function () {
    $('.accordion__head').on('click', function () {
        const $acc = $(this).closest('.accordion');

        $acc.toggleClass('open');
        if ($acc.hasClass('open')) {
            $acc.find('.accordion__body').slideDown(300);
        } else {
            $acc.find('.accordion__body').slideUp(300);
        }
    });
});
