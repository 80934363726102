import { openModal } from "./modals";

const blurCheckout = () => {
    //$('#checkout_submit').prop('disabled', true)
};

const unBlurCheckout = () => {
    //$('#checkout_submit').prop('disabled', false)
};

$(document).ready(() => {
    $("#checkout-form").on("submit", (e) => {
        e.preventDefault();
    });

    $(document).on("change", ".checkout_customer", (e) => {
        e.preventDefault();

        let field = $(e.target).attr("name");
        let value = $(e.target).val();

        let fields = {};

        if (field === "customer[phone]") {
            fields[field] = (
                $("#checkout_phone").data("dial-code") + value
            ).replace(/[^0-9]/g, "");
            fields["customer[phone_country]"] =
                $("#checkout_phone").data("country");
        } else {
            fields[field] = value;
        }

        $.ajax({
            type: "POST",
            url: $("#checkout-form").data("update"),
            data: fields,
            dataType: "JSON",
            beforeSend: () => {
                blurCheckout();
            },
            success: (response) => {},
            error: (response) => {
                notifyError(response.responseJSON.message);
            },
        }).always(() => {
            unBlurCheckout();
        });
    });

    $(document).on("change", ".checkout_payment", (e) => {
        e.preventDefault();

        $.ajax({
            type: "POST",
            url: $("#checkout-form").data("update"),
            data: {
                [$(e.target).attr("name")]: $(e.target).val(),
            },
            dataType: "JSON",
            beforeSend: () => {
                blurCheckout();
            },
            success: (response) => {
                $("#checkout_DeliveryArea").html(response.html.deliveryArea);
                $("#checkout_PaymentArea").html(response.html.paymentArea);
                $("#checkout_ResultArea").html(response.html.resultArea);
            },
            error: (response) => {
                notifyError(response.responseJSON.message);
            },
        }).always(() => {
            unBlurCheckout();
        });
    });

    $(document).on("change", ".checkout_delivery", (e) => {
        e.preventDefault();

        $.ajax({
            type: "POST",
            url: $("#checkout-form").data("update"),
            data: {
                [$(e.target).attr("name")]: $(e.target).val(),
            },
            dataType: "JSON",
            beforeSend: () => {
                blurCheckout();
            },
            success: (response) => {
                $("#checkout_DeliveryArea").html(response.html.deliveryArea);
                // $('#checkout_PaymentArea').html(response.html.paymentArea)
                $("#checkout_ResultArea").html(response.html.resultArea);
                // $('#checkout_BonusArea').html(response.html.bonusArea)
            },
            error: (response) => {
                notifyError(response.responseJSON.message);
            },
        }).always(() => {
            unBlurCheckout();
        });
    });

    $(document).on("change", ".checkout_delivery_region", (e) => {
        e.preventDefault();

        $.ajax({
            type: "POST",
            url: $("#checkout-form").data("update"),
            data: {
                [$(e.target).attr("name")]: $(e.target).val(),
            },
            dataType: "JSON",
            beforeSend: () => {
                blurCheckout();
            },
            success: (response) => {
                $("#checkout_DeliveryArea").html(response.html.deliveryArea);
                // $('#checkout_PaymentArea').html(response.html.paymentArea)
                $("#checkout_ResultArea").html(response.html.resultArea);
                // $('#checkout_BonusArea').html(response.html.bonusArea)
            },
            error: (response) => {
                notifyError(response.responseJSON.message);
            },
        }).always(() => {
            unBlurCheckout();
        });
    });

    $(document).on("change", ".checkout_address", (e) => {
        e.preventDefault();

        $.ajax({
            type: "POST",
            url: $("#checkout-form").data("update"),
            data: {
                [$(e.target).attr("name")]: $(e.target).val(),
            },
            dataType: "JSON",
            beforeSend: () => {
                blurCheckout();
            },
            success: (response) => {},
            error: (response) => {
                notifyError(response.responseJSON.message);
            },
        }).always(() => {
            unBlurCheckout();
        });
    });

    $(document).on("change", "#checkout_privacy_agree", (e) => {
        e.preventDefault();

        $.ajax({
            type: "POST",
            url: $("#checkout-form").data("update"),
            data: {
                [$(e.target).attr("name")]: $(e.target).is(":checked"),
            },
            dataType: "JSON",
            beforeSend: () => {
                blurCheckout();
            },
            success: (response) => {},
            error: (response) => {
                notifyError(response.responseJSON.message);
            },
        }).always(() => {
            unBlurCheckout();
        });
    });

    /*$(document).on('change', '#checkout_bonuses', e => {
        const max = $(e.target).attr('max')
        let value = $(e.target).val()
        if (value < 0 || value > max) {
            value = max
        }

        $(e.target).val(value)
    })*/

    /*$(document).on('click', '#checkout_apply_bonuses', e => {
        e.preventDefault()

        $.ajax({
            type: 'POST',
            url: $('#checkout_apply_bonuses').data('action'),
            data: {
                bonuses: $('#checkout_bonuses').val(),
            },
            dataType: "JSON",
            beforeSend: () => {
                blurCheckout()
            },
            success: response => {},
            error: response => {
                notifyError(response.responseJSON.message);
            }
        }).always(() => {
            unBlurCheckout()

        })
    })*/

    $(document).on("click", "#checkout_submit", (e) => {
        e.preventDefault();

        const formData = new FormData(document.getElementById("checkout-form"));

        let config = {
            type: "POST",
            url: $("#checkout_submit").data("action"),
            data: formData,
            processData: false,
            contentType: false,
            beforeSend: () => {
                invalidateForm("checkout-form");
                blurCheckout();
            },
            success: ({ number, date, redirect }) => {
                $(".popup.show").removeClass("show");

                $("#checkout_success_number").text(number);
                $("#checkout_success_date").text(date);
                $("#checkout-form").trigger("reset");

                window.catchGoogleEvent("zakaz_oformlen");

                (async () => {
                    await openModal("modal_checkoutSuccess", {
                        number: number,
                        date: date,
                    });

                    setTimeout(() => {
                        window.location.replace(redirect);
                    }, 5000);
                })();
            },
            error: (response) => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, value) => {
                        invalidField(`checkout_${key}`, value[0]);
                        /* if (key === 'privacy_agree') {
                            notifyError(value[0]);
                        } else {
                            invalidField(`checkout_${key}`, value[0])
                        }*/
                    });
                } else {
                    notifyError();
                }
            },
        };

        $.ajax(config).always(() => {
            unBlurCheckout();
        });
    });
});
