require('imask');

window.applyMasks = () => {
    $('.input_phone').each(function (key, input) {

        let country = $(input).data('country')
        if (!country) {
            country = $('meta[name="country"]').attr('content')
        }

        let mask, enums

        switch (country) {
            case 'ua':
                mask = '+{380} (CD) 000-00-00'
                enums = [
                    '067', '096', '097', '098', '050', '066', '095', '099',
                    '063', '073', '093', '091', '092', '094'
                ]
                break;

            case 'ru':
                mask = '+{7} (CD) 000-00-00'
                enums = [
                    '900', '901', '902', '903', '904', '905', '906', '908',
                    '909', '910', '911', '912', '913', '914', '915', '916',
                    '917', '918', '919', '920', '921', '922', '923', '924',
                    '925', '926', '927', '928', '929', '930', '931', '932',
                    '933', '934', '936', '937', '938', '939', '941', '950',
                    '951', '952', '953', '954', '955', '956', '958', '960',
                    '961', '962', '963', '964', '965', '966', '967', '968',
                    '969', '970', '971', '977', '978', '980', '981', '982',
                    '983', '984', '985', '986', '987', '988', '989', '991',
                    '992', '993', '994', '995', '996', '997', '999'
                ]
                break;

            case 'by':
            default:
                mask = '+{375} (CD) 000-00-00'
                enums = ['17', '29', '33', '44', '25']
                break;

        }

        IMask(input, {
            mask: mask,
            blocks: {
                CD: {
                    mask: IMask.MaskedEnum,
                    enum: enums
                }
            }
        });
    });
}

applyMasks()
