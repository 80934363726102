$(function () {
    $(".videobanner .video__play").on('click', function (e) {
        e.stopPropagation();
        $(this).closest(".video").addClass("on");
        $(this).closest(".video").find("video").trigger("play");
    });

    $(".videobanner .video__pause").on('click', function (e) {
        e.stopPropagation()
        $(this).closest(".video").removeClass("on");
        $(this).closest(".video").find("video").trigger("pause");
    });
});
