import { log2 } from "ol/math";

const { blockLoading, blockLoaded } = require("../../vendor/blockLoader");

export const triggerGridStyle = (isRow = false) => {
    $(".grid-toggle__icon").removeClass("is-active");
    $(`#grid-toggle-${isRow ? "row" : "card"}`).addClass("is-active");

    $(".page-catalog .grid").toggleClass("is-rows", isRow);
    $(".page-catalog .grid .product-card").toggleClass(
        "product-card--row",
        isRow,
    );
};

export const updateCatalogStore = (key, value) => {
    let storageData = getStorageCatalogData();

    if (key === "f") {
        let newData = {};

        Object.keys(storageData).map((dataKey) => {
            if (dataKey.split("[")[0] !== key) {
                newData[dataKey] = storageData[dataKey];
            }
        });

        storageData = {
            ...newData,
            ...value,
        };
    } else {
        if (typeof value === "object") {
            storageData = {
                ...storageData,
                ...value,
            };
        } else {
            storageData[key] = value;
        }
    }

    setCatalogStore(storageData);

    return true;
};

export const setCatalogStore = (storageData) => {
    localStorage.setItem("catalog", JSON.stringify(storageData));
};

export const getStorageCatalogData = () => {
    return JSON.parse(localStorage.getItem("catalog") ?? "{}");
};

export const queryStringToObject = (url) =>
    Object.fromEntries([...new URLSearchParams(url.split("?")[1])]);

export const objectToQueryString = (queryParameters) => {
    let symbol = null;

    return queryParameters
        ? Object.entries(queryParameters).reduce(
              (queryString, [key, val], index) => {
                  symbol = symbol === null ? "?" : "&";

                  if (typeof val === "string") {
                      queryString += `${symbol}${key}=${val}`;
                  }

                  if (typeof val === "object") {
                      Object.keys(val).forEach((objectKey) => {
                          symbol = symbol === null ? "?" : "&";
                          const objectVal = val[objectKey];

                          if (typeof objectVal === "string") {
                              queryString += `${symbol}${key}[${objectKey}]=${objectVal}`;
                          }

                          if (Array.isArray(objectVal)) {
                              queryString += `${symbol}${key}[${objectKey}]=${objectVal.join(",")}`;
                          }
                      });
                  }

                  if (Array.isArray(val)) {
                      queryString += `${symbol}${key}=${val.join(",")}`;
                  }

                  return queryString;
              },
              "",
          )
        : "";
};

export const resolveCatalogUrl = (page = null) => {
    let catalogStorageData = getStorageCatalogData();

    catalogStorageData = {
        ...catalogStorageData,
        perPage:
            catalogStorageData.perPage !== undefined
                ? catalogStorageData.perPage
                : $("#catalog-perpage").val(),
        sort:
            catalogStorageData.sort !== undefined
                ? catalogStorageData.sort
                : $("#catalog-sort").val(),
    };

    if ("page" in catalogStorageData) {
        delete catalogStorageData.page;
    }

    if (page) {
        catalogStorageData = { ...catalogStorageData, page };
    }

    let urlParams = objectToQueryString(catalogStorageData);

    return (
        location.protocol + "//" + location.host + location.pathname + urlParams
    );
};

export const loadProducts = (page = null) => {
    const { row } = getStorageCatalogData();

    let url = resolveCatalogUrl(page);

    $.ajax({
        type: "POST",
        url: url,
        beforeSend: (request) => {
            blockLoading($("#catalog_grid"));
        },
        success: (response) => {
            $("#products_list").html(response.html);
            history.pushState(null, null, url);
        },
        error: (response) => {
            notifyError();
        },
    }).always(() => {
        blockLoaded($("#catalog_grid"));
        triggerGridStyle(row ?? false);
    });
};

$(() => {
    $(".page-catalog #catalog-sort").on("change", (e) => {
        e.preventDefault();

        if (updateCatalogStore("sort", $(e.target).val())) {
            loadProducts();
        }
    });

    $(".page-catalog #catalog-perpage").on("change", (e) => {
        e.preventDefault();

        if (updateCatalogStore("perPage", $(e.target).val())) {
            loadProducts();
        }
    });

    $("form#filter").on("submit", (e) => {
        e.preventDefault();

        const formData = $(e.target).serializeJSON();

        let setData = {};
        if (Object.keys(formData).length) {
            Object.keys(formData.f).map((key) => {
                setData[`f[${key}]`] = Array.isArray(formData.f[key])
                    ? formData.f[key].join(",")
                    : formData.f[key];
            });
        }

        if (updateCatalogStore("f", setData)) {
            loadProducts();
        }
    });

    $(document).on("click", ".catalog_paginator a", (e) => {
        e.preventDefault();

        const link = $(e.target);
        const href = link.attr("href");

        const pageParams = new URLSearchParams(href);
        loadProducts(pageParams.get("page"));

        $("html, body").animate(
            {
                scrollTop: 0,
            },
            300,
        );
    });

    if ($("#catalog_page").length) {
        let store = queryStringToObject(window.location.href);

        setCatalogStore(store);

        const { perPage, sort } = getStorageCatalogData();

        if (perPage) {
            $("#catalog-perpage").val(perPage);
            reloadSelect(document.getElementById("catalog-perpage"));
        } else {
            updateCatalogStore("perPage", $("#catalog-perpage").val());
        }

        if (sort) {
            $("#catalog-sort").val(sort);
            reloadSelect(document.getElementById("catalog-sort"));
        } else {
            updateCatalogStore("sort", $("#catalog-sort").val());
        }

        loadProducts();
    }
});
