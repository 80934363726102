import { openModal } from "./modals";

$(document).ready(() => {
    $(document).on("click", ".countries-list__save", (e) => {
        toggleCountry($(e.target).val());
    });

    $("#popup-region").on("submit", (e) => {
        e.preventDefault();

        toggleCountry($(".region_country_radio:checked").val());
    });

    $.ajax({
        type: "POST",
        url: $("#check_region").data("action"),
        dataType: "JSON",
        processData: false,
        contentType: false,
        success: ({ status }) => {
            if (!status) {
                openModal("modal_regions");
            }
        },
        error: (xhr, desc, err) => {},
    });
});

const toggleCountry = (code) => {
    $.ajax({
        type: "POST",
        url: $(".countries-list__save").data("action"),
        data: {
            code: $(".countries-list__btn .btn.is-active").data("country-code"),
        },
        dataType: "json",
        success: (response) => {
            if (response.redirect) {
                window.location.replace(response.redirect);
            } else {
                location.reload();
            }
        },
        error: (xhr, status, err) => {
            notifyError();
        },
    });
};
