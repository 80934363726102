$(function () {
  $('.region-select').each(function () {
    const $region = $(this);

    $(this).find('.region-select__selected').on('click', function (e) {
      e.stopPropagation();
      $region.find('.region-select__arrow, .region-cloud').toggleClass('is-active');
    });

    $(this).find('.region-cloud__buttons .btn').on('click', function (e) {
      $('.region-select').find('.region-select__arrow, .region-cloud').removeClass('is-active');
    });
  });

  $('body').on('click', function (e) {
    if (!$(e.target).closest('.region-select').length) {
      $('.region-select').find('.region-select__arrow, .region-cloud').removeClass('is-active');
    }
  });
});