$(function () {
    $('.page-product .page-product__slider').slick({
        rows: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        adaptiveHeight: true,
        speed: 600,
        fade: true,
        cssEase: 'ease',
        arrows: true,
        dots: true,
        prevArrow: '<span class="slider__arr slider__arr_l"><img src="/media/icons/arr-l.svg" alt></span>',
        nextArrow: '<span class="slider__arr slider__arr_r"><img src="/media/icons/arr-r.svg" alt></span>'
    });

    $('.page-product .slider-nav__item').on('click', function () {
        $('.page-product__slider').slick('slickGoTo', $('.slider-nav__item').index(this));
    });

    $('.page-product .page-product__track').stick_in_parent({ offset_top: 140 });

    $('.page-product .page-product__tabs .tabs-nav .btn').on('click', function (e) {
        e.preventDefault();
        if (!$(this).hasClass('is-active')) {
            $('.page-product__tabs .tabs-nav .btn').removeClass('is-active');
            $(this).addClass('is-active');
            $('.page-product__tabs .tabs__body').removeClass('is-active');
            $(`.page-product__tabs .tabs__body[data-id="${$(this).attr('href')}"]`).addClass('is-active');
        }
    });

    $('.page-product__scroller').on('click', function (e) {
        e.preventDefault();
        $('html').animate({ scrollTop: $($(this).attr('href')).offset().top - $('header').height() }, 300);
    });

    $('.page-product .product-nav__wish').on('click', function () {
        $(this).toggleClass('is-active');
    });

    $('.page-product .product-nav__quantity .quantity__btn').on('click', function () {
        const $input = $(this).closest('.quantity').find('input');
        let inputVal = parseInt($input.val());
        if ($(this).hasClass('plus')) {
            inputVal += 1;
        } else if ($(this).hasClass('minus') && inputVal > 1) {
            inputVal -= 1;
        }

        $input.val(inputVal);
        const price = parseFloat($('.page-product__price p:not(.old)').text().replace(',', '.'));
        $('.page-product .product-nav__total span').text((inputVal * price).toFixed(2).replace('.', ','));
    });
});
