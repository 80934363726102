$(function () {
    $('.article-nav__button--like').on('click', e => {
        e.preventDefault()

        const target = $(e.currentTarget)

        $.ajax({
            type: 'POST',
            url: target.data('action'),
            dataType: 'json',
            success: ({ count, hasLike }) => {
               $('#likes_count').html(count)
            },
            error: (xhr, status, err) => {
                notifyError();
            }
        })
    })
});
