const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

$(document).ready(() => {

    if ($('#dealers-map').length) {
        mapboxgl.accessToken = process.env.MIX_MAP_BOX;
        const map = new mapboxgl.Map({
            container: 'dealers-map',
            style: 'mapbox://styles/alesia-sviridova/cl4p2oek0000015qi28i0uh5x',
            center: [29.927, 54.111],
            zoom: 1
        });
        map.addControl(new mapboxgl.NavigationControl());

        drawDealersMap(map)

        $(".page-dealers .dealer__name").on('click', e => {
            const target = $(e.target)

            map.flyTo({
                center: [target.data('lng'), target.data('lat')],
                zoom: 10
            })
        });

        $('#dealers-country').on('change', e => {
            e.preventDefault()

            const countryCode = $(e.currentTarget).val()

            $('.dealers__block').removeClass('is-active')
            $(`#dealers_list_${countryCode}`).addClass('is-active')

            drawDealersMap(map)
        })
    }

    if ($("main").hasClass("page-dealers")) {
        $(".page-dealers .dealers__title").click(function () {
            var $dealers = $(this).closest(".dealers__block");
            var isOpen = $dealers.hasClass("open");

            $(".page-dealers .dealers__block.open .dealers__items").slideUp(300);
            $(".page-dealers .dealers__block.open").removeClass("open");

            if (!isOpen) {
                $dealers.addClass("open");
                $dealers.find(".dealers__items").slideDown(300);
            }
        });
    }
})

const drawDealersMap = (map) => {
    $.ajax({
        type: 'GET',
        url: `/api/dealers/list?code=${$('#dealers-country').val()}`,
        data: {
            lang: $('html').attr('lang'),
        },
        beforeSend: () => {
            $('.mapboxgl-popup').remove()
            map.flyTo({
                center: [29.927, 54.111],
                zoom: 1
            })
            $('.mapboxgl-marker').remove()
        },
        dataType: "JSON",
        success: response => {
            $.each(response, (index, dealer) => {
                new mapboxgl.Marker({
                    color: '#ff0000'
                })
                    .setLngLat([dealer.longitude, dealer.latitude])
                    .setPopup(new mapboxgl.Popup().setHTML(`
                                                            <p><b>${dealer.title}</b></p>
                                                            <p>${dealer.address}</p>
                                                            <p><b>${dealer.phone}</b></p>
                                                            <p><b>${dealer.email}</b></p>

                    `)).addTo(map);
            })
        },
        error: response => {

        }
    })
}
