$(function () {
    $('.page-home .news-slider .slider').slick({
        rows: 0,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        speed: 600,
        cssEase: 'ease',
        arrows: true,
        dots: true,
        prevArrow: '<span class="slider__arr slider__arr_l"><img src="/media/icons/arr-l.svg" alt></span>',
        nextArrow: '<span class="slider__arr slider__arr_r"><img src="/media/icons/arr-r.svg" alt></span>',
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1 } },
        ],
    });
});
