import * as cookieService from "../../vendor/cookie/cookieService";

window.closePopup = () => {
    $(".popup.show").removeClass("show");
    $("body").removeClass("popup-on");
}

$(() => {
    /*const welcome = cookieService.getCookie('_visit')
    if (!welcome) {
        cookieService.setCookie('_visit', 1)

        setTimeout(() => {
            $("body").addClass("popup-on")
            $(".popup.show").removeClass("show")
            $('#welcome_modal').addClass("show")
        }, 1500)
    }*/

    $(document).on('submit', "#catalog-download-form", e => {
        e.preventDefault()

        const target = e.target

        $.ajax({
            type: 'POST',
            url: $(target).data('action'),
            dataType: "JSON",
            data: new FormData(target),
            processData: false,
            contentType: false,
            beforeSend: () => {
                invalidateForm($(target).attr('id'))
            },
            success: (response) => {
                closePopup()
                $(target).trigger("reset")

                catchGoogleEvent('skachat_katalogrb')

                if (response.link) {
                    const link = document.createElement('a')
                    link.setAttribute('href', response.link)
                    link.setAttribute('target', '_blank')
                    link.click()
                }
            },
            error: response => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, value) => {
                        invalidField(`${$(target).attr('id')}-${key}`, value[0])
                    });
                } else {
                    notifyError(response.responseJSON.message ?? null);
                }
            }
        })
    });
});
