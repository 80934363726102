$(function () {
    $(".navbar-search").on('click', function () {
        $(this).addClass("open");
    });

    $(".navbar-search__close").on('click', function (e) {
        e.stopPropagation();
        $(this).closest(".navbar-search").removeClass("open");
    });

    $(".navbar-search__form img, .mobmenu__search img").on('click', function () {
        $(this).closest("form").trigger('submit');
    });

    $(".navbar-account").on('click', function () {
        $(this).addClass("open");
    });

    $(".navbar-account__close").on('click', function (e) {
        e.stopPropagation();
        $(this).closest(".navbar-account").removeClass("open");
    });


    $(window).on('scroll', function () {
        $(".header .topbar").toggleClass("min", $(window).scrollTop() >= 40);
        $(".header .navbar").toggleClass("min", $(window).scrollTop() >= 40);
    });


    /**********************************************************************************************/
    $(".topbar-select__mobicon").on('click', function (e) {
        e.stopPropagation();
        $(this).closest(".topbar-select").find(".topbar-moblist").addClass("open");
    });

    $(".topbar-tel__mobicon").on('click', function (e) {
        e.stopPropagation();
        $(this).closest(".topbar-tel").find(".topbar-moblist").addClass("open");
    });

    $(".topbar-moblist__icon").on('click', function (e) {
        e.stopPropagation();
        $(this).closest(".topbar-moblist").removeClass("open");
    });


    /**********************************************************************************************/
    $(".topbar__burger, .navbar__burger").on('click', function (e) {
        e.stopPropagation();
        $("body").addClass("mobmenu-on");
    });

    $(".mobmenu__close").on('click', function () {
        $("body").removeClass("mobmenu-on");
    });
});