export const applyPhoneMasks = () => {
    $('input[type="tel"]').each((key, input) => {
        const iti = window.intlTelInput(input, {
            initialCountry: $(input).data("country") ?? "by",
            separateDialCode: true,
            nationalMode: false,
            preferredCountries:
                $('meta[name="countriesCodes"]').attr("content")?.split(",") ??
                [],
            showSelectedDialCode: true,
            strictMode: true,
            countrySearch: false,
            customPlaceholder: function (
                selectedCountryPlaceholder,
                selectedCountryData,
            ) {
                if (selectedCountryData.iso2 === "ru") {
                    return "912 345-68-11";
                } else {
                    return selectedCountryPlaceholder;
                }
            },
            utilsScript: window.utils,
        });

        $(input).data("country", iti.getSelectedCountryData().iso2);
        $(input).data("dial-code", iti.getSelectedCountryData().dialCode);

        initMask(input, iti);

        input.addEventListener("countrychange", () => {
            $(input).data("country", iti.getSelectedCountryData().iso2);
            $(input).data("dial-code", iti.getSelectedCountryData().dialCode);

            initMask(input, iti, true);
            //isPhoneValid(input);
        });

        input.addEventListener("change", () => {
            //isPhoneValid(input)
        });
        input.addEventListener("keyup", () => {
            //isPhoneValid(input)
        });

        function isPhoneValid(item) {
            if (iti.isValidNumber()) {
                $(item).removeClass("is-invalid");
            } else {
                $(item).addClass("is-invalid");
            }
        }

        function initMask(input, iti, reset = false) {
            if (reset) {
                input.value = "";
            }

            switch (iti.getSelectedCountryData().iso2) {
                case "by":
                    $(input).mask("00 000-00-00");
                    break;

                case "ua":
                    $(input).mask("00 000 0000");
                    break;

                case "ru":
                    $(input).mask("000 000-00-00");
                    break;

                default:
                    break;
            }
        }
    });
};

$(function () {
    /**********************************************************************************************/
    /**********************************          SELECT          **********************************/
    /**********************************************************************************************/
    $(".select").each(function () {
        var $select = $(this).find("select");

        var selectContent = '<div class="select__selected">';
        selectContent +=
            "<span>" + $select.find("option:selected").text() + "</span>";
        selectContent += '<img src="/media/icons/arr-black.svg" alt></div>';

        selectContent += '<div class="select__items hide">';
        $select.find("option").each(function ($index) {
            selectContent += '<div data-val="' + $(this).val() + '"';
            selectContent += ' class="select__item';
            if ($(this).is(":selected")) selectContent += " same";
            selectContent += '">' + $(this).text() + "</div>";
        });
        selectContent += "</div>";

        $select.after(selectContent);
    });

    $("body").on("click", ".select__item", function () {
        var field = $(this).closest(".select");
        field.find(".select__selected span").html($(this).html());
        field.find(".same").removeClass("same");
        $(this).addClass("same");
        field.find("select").val($(this).attr("data-val")).change();
    });

    $("body").on("click", ".select__selected", function (e) {
        e.stopPropagation();
        $(this).closest(".select").toggleClass("open");
        $(this).closest(".select").find(".select__items").toggleClass("hide");
    });

    $("body").on("click", function () {
        $(".select").removeClass("open");
        $(".select__items").addClass("hide");
    });

    $(".select select").on("change", function (e) {
        reloadSelect(e.currentTarget);
    });

    /**********************************************************************************************/
    /**********************************        INPUT FILE        **********************************/
    /**********************************************************************************************/
    $(".formarea__input--file").each(function () {
        const $inputFile = $(this).find('input[type="file"]');
        const $inputText = $(this).find('input[type="text"]');

        $inputFile.on("change", function () {
            $inputText.val(
                $inputFile.val() ? $inputFile.val().split("\\").pop() : "",
            );
        });
    });

    /**********************************************************************************************/
    /**********************************      INPUT PASSWORD      **********************************/
    /**********************************************************************************************/
    $(document).on(
        "click",
        ".formarea__input--password .formarea__input-icon",
        (e) => {
            const $input = $(e.currentTarget)
                .parent(".formarea__input--password")
                .find("input");

            $input.attr(
                "type",
                $input.attr("type") === "password" ? "text" : "password",
            );
        },
    );

    /**********************************************************************************************/
    /**********************************           RANGER         **********************************/
    /**********************************************************************************************/
    $(".ranger__track").each(function () {
        var min = parseFloat($(this).closest(".ranger").data("min"));
        var max = parseFloat($(this).closest(".ranger").data("max"));
        var from = parseFloat($(this).closest(".ranger").data("from"));
        var to = parseFloat($(this).closest(".ranger").data("to"));
        var decimals = parseInt($(this).closest(".ranger").data("decimals"));
        if (decimals == 0) decimals = 0.1;
        var step = parseFloat(1 / (10 * decimals));

        $(this)
            .closest(".ranger")
            .find(".ranger__input_from")
            .val(from.toFixed(decimals));
        $(this)
            .closest(".ranger")
            .find(".ranger__input_to")
            .val(to.toFixed(decimals));

        $(this).slider({
            range: true,
            min: min,
            max: max,
            step: step,
            values: [from, to],
            slide: function (event, ui) {
                $(this)
                    .closest(".ranger")
                    .find(".ranger__val_from span")
                    .text(ui.values[0].toFixed(decimals));
                $(this)
                    .closest(".ranger")
                    .find(".ranger__val_to span")
                    .text(ui.values[1].toFixed(decimals));
            },
        });
    });

    /**********************************************************************************************/
    /**********************************           PHONE          **********************************/
    /**********************************************************************************************/
    $.validator.addMethod(
        "tel",
        function (value, element) {
            return this.optional(element) || !$(element).hasClass("is-invalid");
        },
        "Пожалуйста, введите корректный телефон.",
    );

    applyPhoneMasks();
});
