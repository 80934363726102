import { applyPhoneMasks } from "../formarea";

export const openModal = (href, data = {}, resolve = null) => {
    $.ajax({
        type: "POST",
        url: "/modals",
        data: {
            modal: href,
            data: data,
        },
        dataType: "json",
        success: ({ html }) => {
            $("body").addClass("popup-on");
            $("#popup_wrap").html(html);
            $("#popup_wrap > div").addClass("show");
            applyPhoneMasks();
        },
        error: (xhr, status, err) => {
            notifyError();
        },
    }).always(() => {
        if (resolve) {
            resolve();
        }
    });
};

$(() => {
    $(document).on("click", ".open-popup", (e) => {
        e.preventDefault();

        openModal($(e.currentTarget).attr("href"));
    });

    $(document).on("click", ".popup__close", (e) => {
        e.preventDefault();
        $("#popup_wrap").html(null);
        $("body").removeClass("popup-on");
    });

    $(document).on("click", ".overlay", (e) => {
        e.preventDefault();
        $("#popup_wrap").html(null);
        $("body").removeClass("popup-on");
    });
});
