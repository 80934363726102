$(function () {
    /**********************************************************************************************/
    $(document).on(
        "click",
        ".popup-register .popup-register__nav label",
        (e) => {
            e.preventDefault();

            if ($(e.currentTarget).find("input:checked").length == 0) {
                $(".popup.show").removeClass("show");
                $($(e.currentTarget).find("input").data("href")).addClass(
                    "show",
                );
            }
        },
    );

    /**********************************************************************************************/
    $(document).on("click", ".popup-country .countries-list__btn .btn", (e) => {
        if (!$(e.currentTarget).hasClass("is-active")) {
            $(e.currentTarget)
                .closest(".countries-list")
                .find(".countries-list__btn .btn.is-active")
                .removeClass("is-active");
            $(e.currentTarget).addClass("is-active");
        }
    });
});
