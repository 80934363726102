$(document).ready(() => {
    $("#currency_toggle").on("change", e => {
        toggleCurrency($(e.target).val())
    });
});

const toggleCurrency = (code) => {

    const select = $("#currency_toggle")

    $.ajax({
        type: 'POST',
        url: select.data('action'),
        data: {
            code: code
        },
        dataType: 'json',
        success: response => {
            location.reload()
        },
        error: (xhr, status, err) => {
            notifyError();
        }
    })
}
