import { triggerGridStyle, updateCatalogStore } from "../scripts/catalog";

$(function () {
    $(".sidebar__toggler").on("click", function () {
        var $sidebar = $(this).closest(".sidebar");
        $sidebar.toggleClass("open");
        if ($sidebar.hasClass("open"))
            $sidebar.find(".sidebar__wrap").slideDown(300);
        else $sidebar.find(".sidebar__wrap").slideUp(300);
    });

    $(".catalog-grid .grid-tags__list .btn").on("click", function () {
        $(this).toggleClass("is-active");
    });

    $(".catalog-grid .grid-head__filter").on("click", function () {
        $("body").addClass("popup-on");
        $(".page-catalog__sidebar .sidebar__wrap").addClass("show");
    });

    $(
        ".page-catalog__sidebar .sidebar__wrap-close, .page-catalog__sidebar .sidebar__btn",
    ).on("click", function () {
        $("body").removeClass("popup-on");
        $(".page-catalog__sidebar .sidebar__wrap").removeClass("show");
    });

    $(window).on("load resize", function () {
        const $tags = $(".catalog-grid .grid-tags");
        const $tagsList = $tags.find(".grid-tags__list");
        $tagsList.css("height", "auto");
        const isLong =
            $tagsList.height() >
            2 * $tagsList.find(".btn:first-child").outerHeight() + 10;
        $tagsList.css("height", "");
        $tags.toggleClass("is-full", !isLong);
        $tags.find(".grid-tags__more").toggleClass("hide", !isLong);
    });

    $(".catalog-grid .grid-tags__more").on("click", function () {
        const $tags = $(this).closest(".grid-tags");
        const $tagsList = $tags.find(".grid-tags__list");
        $tags.toggleClass("is-full");
        const currHeight = $tagsList.height();
        const newHeight = $tags.hasClass("is-full")
            ? $tagsList.css("height", "auto").height()
            : $tagsList.css("height", "").height();
        $tagsList.height(currHeight).animate({ height: newHeight }, 300);
    });

    $(".page-catalog .grid-toggle__icon").on("click", (e) => {
        e.preventDefault();

        $(".page-catalog .grid-toggle__icon").toggleClass("is-active");

        const type = $(e.currentTarget).data("type");

        const isRow = type === "row";

        triggerGridStyle(isRow);
        updateCatalogStore("row", isRow);
    });

    $(document).on(
        "click",
        ".product-card__quantity .quantity__btn",
        function () {
            const $input = $(this).closest(".quantity").find("input");
            const inputVal = parseInt($input.val());
            if ($(this).hasClass("plus")) {
                $input.val(inputVal + 1);
            } else if ($(this).hasClass("minus") && inputVal > 1) {
                $input.val(inputVal - 1);
            }
        },
    );
});
