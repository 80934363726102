window.reloadSelect = (select) => {
    var field = $(select).closest(".select");
    if ($(select).val() !== field.find(".same").data("val")) {
        field
            .find(".select__selected span")
            .html(field.find("option[value='" + $(select).val() + "']").text());
        field.find(".same").removeClass("same");
        field
            .find(".select__item[data-val='" + $(select).val() + "']")
            .addClass("same");
    }
};

$(() => {
    $("input, select, textarea").on("focus", (e) => {
        const target = $(e.target);

        if (target.hasClass("error")) {
            target.removeClass("error");
        }
    });

    $(".formarea__input input, .formarea__input textarea").each((i, item) => {
        makeLabel(item);
    });

    $(".formarea__input input, .formarea__input textarea").on("focus", (e) => {
        makeFocusLabel(e.target);
    });

    $(".formarea__input input, .formarea__input textarea").on(
        "focusout",
        (e) => {
            makeFocusoutLabel(e.target);
        },
    );
});

const makeLabel = (item) => {
    const el = $(item);

    if (el.attr("type") !== "tel") {
        const parent = el.parent();

        const placeholder = el.attr("placeholder");

        if (placeholder && placeholder.length && el.val().length) {
            parent.prepend(`<span class="focus_label">${placeholder}</span>`);
        }
    }
};

const makeFocusLabel = (item) => {
    const el = $(item);
    if (el.attr("type") !== "tel") {
        const parent = el.parent();

        const placeholder = el.attr("placeholder");

        if (placeholder.length && !parent.find(".focus_label").length) {
            parent.prepend(`<span class="focus_label">${placeholder}</span>`);
        }
    }
};

const makeFocusoutLabel = (item) => {
    const el = $(item);
    const parent = el.parent();

    parent.find(".focus_label").each((i, label) => {
        if (!el.val().length) {
            $(label).remove();
        }
    });
};
