$(document).ready(() => {
    /* LOGIN */
    $(document).on('submit', '#form_login', e => {
        e.preventDefault()

        $.ajax({
            type: 'POST',
            url: $(e.target).data('action'),
            dataType: "JSON",
            data: new FormData(e.target),
            processData: false,
            contentType: false,
            success: response => {
                catchGoogleEvent('vhod_v_lk')

                if (response.redirect) {
                    window.location.replace(response.redirect);
                }
            },
            error: response => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, value) => {
                        $(`#login_${key}`).addClass('error')
                    });
                } else {
                    notifyError(response.responseJSON.message ?? null);
                }
            }
        })
    })

    /* REGISTER */
    $(document).on('submit', '.form_register', e => {
        e.preventDefault()

        let data = new FormData(e.target)

        const type = $(e.target).data('type')
        data.set('type', type)

        const phoneInput = $('#organization_phone')
        const phone = (phoneInput.data('dial-code') + phoneInput.val()).replace(/[^0-9]/g,"")
        const phone_country = phoneInput.data('country')

        data.set('phone', phone)
        data.set('phone_country', phone_country)

        $.ajax({
            type: 'POST',
            url: $(e.target).data('action'),
            dataType: "JSON",
            data: data,
            processData: false,
            contentType: false,
            beforeSend: () => {
                invalidateForm($(e.target).attr('id'))
            },
            success: response => {
                $(".popup.show").removeClass("show")
                $('#register-user-confirm').addClass("show")

                catchGoogleEvent('registraciya_v_lk')

                if (response.redirect) {
                    setTimeout(() => {
                        window.location.replace(response.redirect);
                    }, 4000)
                }
            },
            error: response => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, value) => {
                        invalidField(`${type}_${key}`, value[0])
                    });
                } else {
                    notifyError();
                }
            }
        })
    })

    /* FORGOT  */
    $(document).on('submit', '#form_forgot', e => {
        e.preventDefault()

        let data = new FormData(e.target)

        $.ajax({
            type: 'POST',
            url: $(e.target).data('action'),
            dataType: "JSON",
            data: data,
            processData: false,
            contentType: false,
            beforeSend: () => {
                invalidateForm($(e.target).attr('id'))
            },
            success: response => {
                $(".popup.show").removeClass("show");
                $('#forgot_sent').addClass("show");
            },
            error: response => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, value) => {
                        invalidField(`form_forgot_${key}`, value[0])
                    });
                } else {
                    notifyError();
                }
            }
        })
    })

    /* RESET */
    $(document).on('submit', '#form_reset', e => {
        e.preventDefault()

        let data = new FormData(e.target)

        $.ajax({
            type: 'POST',
            url: $(e.target).data('action'),
            dataType: "JSON",
            data: data,
            processData: false,
            contentType: false,
            beforeSend: () => {
                invalidateForm($(e.target).attr('id'))
            },
            success: response => {
                if (response.redirect) {
                    window.location.replace(response.redirect);
                }
            },
            error: response => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, value) => {
                        invalidField(`${$(e.target).attr('id')}_${key}`, value[0])
                    });
                } else {
                    notifyError();
                }
            }
        })
    })

    /* UPDATE */
    $(document).on('submit', '#account_update', e => {
        e.preventDefault()

        let data = new FormData(e.target)

        const phoneInput = $('#account-phone')
        const phone = (phoneInput.data('dial-code') + phoneInput.val()).replace(/[^0-9]/g,"")
        const phone_country = phoneInput.data('country')

        data.set('phone', phone)
        data.set('phone_country', phone_country)

        $.ajax({
            type: 'POST',
            url: $(e.target).data('action'),
            dataType: "JSON",
            data: data,
            processData: false,
            contentType: false,
            beforeSend: () => {
                invalidateForm($(e.target).attr('id'))
            },
            success: response => {
                if (response.redirect) {
                    window.location.replace(response.redirect)
                } else {
                    notifySuccess(response.message)
                }
            },
            error: response => {
                if (response.status === 422) {
                    if (response.responseJSON.message) {
                        notifyError(response.responseJSON.message);
                    }

                    $.each(response.responseJSON.errors, (key, value) => {
                        invalidField(`account-${key}`, value[0])
                    });
                } else {
                    notifyError()
                }
            }
        })
    })
})
