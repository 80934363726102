$(function () {
    $(".checkout-delivery__checklist input[name='checkout_delivery']").on('change', function () {
        if ($(this).val() == '0') {
            $(".checkout-delivery__self").removeClass("hide");
            $(".checkout-delivery__fields").addClass("hide");
        } else {
            $(".checkout-delivery__self").addClass("hide");
            $(".checkout-delivery__fields").removeClass("hide");
        }
    });

    $(".checkout-personal input[name='customer[has_contract]']").on('change', (e) => {
        const input = $(e.target)
        const fileInput = input.closest('.checkout__col-block').find('.formarea__input--file')

        fileInput.toggleClass('hide', input.val() === '1')
    });
});
