$(document).ready(() => {
    /* CONTACTS */
    $(document).on('submit', '#contacts-form', e => {
        e.preventDefault()

        const target = e.target

        $.ajax({
            type: 'POST',
            url: $(target).data('action'),
            dataType: "JSON",
            data: new FormData(target),
            processData: false,
            contentType: false,
            beforeSend: () => {
                invalidateForm($(target).attr('id'))
            },
            success: response => {

                catchGoogleEvent('kontaktu_forma')

                $('#form_contacts__answers').addClass('show').html(response.message)
                $(target).trigger("reset")
            },
            error: response => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, value) => {
                        invalidField(`${$(target).attr('id')}-${key}`, value[0])
                    });
                } else {
                    notifyError();
                }
            }
        })
    })
})
