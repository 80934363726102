window.$ = window.jQuery = require("jquery");

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
});

window.toastr = require("toastr/toastr");

window.notifySuccess = (message, title) => {
    toastr.success(message, title, { timeOut: 3000 });
};

window.notifyWarning = (message, title) => {
    toastr.warning(message, title, { timeOut: 3000 });
};

window.notifyError = (message = "Что-то пошло не так :(", title) => {
    toastr.error(message, title, { timeOut: 3000 });
};

window.ol = require("ol");

window.catchGoogleEvent = (eventName) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: eventName });
};

/* libraries */
require("slick-carousel");
require("jquery.maskedinput");
require("jquery-validation");
require("jquery-ui-dist/jquery-ui.min");
require("sticky-kit/dist/sticky-kit");
window.intlTelInput = require("intl-tel-input");
window.utils = require("intl-tel-input/build/js/utils");
window.mask = require("jquery-mask-plugin");
require("jquery-lazy");
require("jquery-serializejson");

/* scripts */
require("./base");
require("./scripts/errorsFields");
require("./scripts/catalog");
require("./scripts/checkout");
require("./scripts/country");
require("./scripts/currency");
require("./scripts/language");
require("./scripts/masks");
require("./scripts/meta");
require("./scripts/profile");
require("./scripts/popup");
require("./scripts/formarea");
require("./scripts/news-article");
require("./scripts/modals");

require("./formarea");

/* elements */
require("./elements/accordion");
require("./elements/cookie-message");
require("./elements/popup");
require("./elements/region-select");

/* layout */
require("./layout/header");
require("./layout/footer");

/* pages */
require("./pages/catalog");
require("./pages/checkout");
require("./pages/cart");
require("./pages/contacts");
require("./pages/dealers");
require("./pages/homepage");
require("./pages/product");
require("./pages/history");
require("./pages/about");
require("./pages/news-article");

$(".lazy").Lazy({
    onError: (element) => {},
});
