$(function () {
    $(document).on('click', '.plus_to_cart', e => {
        e.preventDefault()

        const target = $(e.currentTarget)
        const productId = target.data('productId')
        const count = $(`#add_to_cart_count_${productId}`).length ? $(`#add_to_cart_count_${productId}`).val() : 1

        $.ajax({
            type: 'POST',
            url: $(e.currentTarget).data('action'),
            dataType: "JSON",
            data: {
                count
            },
            success: response => {
                $('.cart_counter').html(response.cart.products_count)

                $('#cart_products_area').html(response.html.productsArea)
                $('#cart_coupon_area').html(response.html.couponArea)
                $('#cart_final_area').html(response.html.finalArea)

                notifySuccess(response.message)
            },
            error: response => {
                notifyError(response.responseJSON.message);
            }
        })
    })

    $(document).on('click', '.minus_from_cart', e => {
        e.preventDefault()

        $.ajax({
            type: 'POST',
            url: $(e.currentTarget).data('action'),
            dataType: "JSON",
            success: response => {
                $('.cart_counter').html(response.cart.products_count)

                $('#cart_products_area').html(response.html.productsArea)
                $('#cart_coupon_area').html(response.html.couponArea)
                $('#cart_final_area').html(response.html.finalArea)

                notifySuccess(response.message)
            },
            error: response => {
                notifyError(response.responseJSON.message);
            }
        })
    })

    $(document).on('click', '.cart-item__remove', e => {
        e.preventDefault()

        $.ajax({
            type: 'POST',
            url: $(e.currentTarget).data('action'),
            dataType: "JSON",
            success: response => {
                $('.cart_counter').html(response.cart.products_count)

                $('#cart_products_area').html(response.html.productsArea)
                $('#cart_coupon_area').html(response.html.couponArea)
                $('#cart_final_area').html(response.html.finalArea)

                notifySuccess(response.message)
            },
            error: response => {
                notifyError(response.responseJSON.message);
            }
        })
    })

    $(document).on('click', '#apply_coupon', e => {
        e.preventDefault()

        $.ajax({
            type: 'POST',
            url: $(e.currentTarget).data('action'),
            data: {
                code: $('#coupon-code').val()
            },
            dataType: "JSON",
            success: response => {
                $('#cart_products_area').html(response.html.productsArea)
                $('#cart_coupon_area').html(response.html.couponArea)
                $('#cart_final_area').html(response.html.finalArea)

                notifySuccess(response.message)
            },
            error: response => {
                notifyError(response.responseJSON.message);
            }
        })
    })
});
