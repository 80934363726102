$(function () {
    $('.page-article__slider').slick({
        rows: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        adaptiveHeight: true,
        speed: 600,
        fade: true,
        cssEase: 'ease',
        arrows: true,
        dots: false,
        prevArrow: '<span class="slider__arr slider__arr_l"><img src="/media/icons/arr-l.svg" alt></span>',
        nextArrow: '<span class="slider__arr slider__arr_r"><img src="/media/icons/arr-r.svg" alt></span>',
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    arrows: false,
                    dots: true,
                }
            },
        ],
    });

    $('.page-article .article-nav__button--copy').on('click', function (e) {
        e.preventDefault();
        navigator.clipboard.writeText(location.href);
    });
});
