$(document).ready(() => {
    $("#language_toggle").on("change", e => {
        toggleLanguage($(e.target).val())
    });
});

const toggleLanguage = (code) => {
    const select = $("#language_toggle")

    $.ajax({
        type: 'POST',
        url: select.data('action'),
        data: {
            code: code
        },
        dataType: 'json',
        success: response => {
            location.reload()
        },
        error: (xhr, status, err) => {
            notifyError();
        }
    })
}
