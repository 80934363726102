$(document).ready(() => {

    $('.meta_btn').on('click', e => {
        e.preventDefault()

        const modal = $('.meta_modal')
        modal.hasClass('meta_modal_show') ? modal.removeClass('meta_modal_show') : modal.addClass('meta_modal_show');
    })

    $("#meta_form").on('submit', e => {
        e.preventDefault()

        const form = $(e.target)

        $.ajax({
            type: 'POST',
            url: form.data('action'),
            data: form.serialize(),
            dataType: 'json',
            success: response => {
                notifySuccess('обновлено');
            },
            error: (xhr, status, err) => {
                notifyError('обновление не удалось');
            }
        })
    });
})
