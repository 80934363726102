window.invalidField = (id, message) => {
    const field = $(`#${id}`)

    if (field.attr('type') === 'checkbox') {
        $(field).closest('label').addClass('error')
    } else {
        field.addClass('error')
        $(`#${id}-error`).remove()
        field.closest('.formarea__input').append(`<label id="${id}-error" class="error" style="display: inline;">${message}</label>`)
    }
}

window.invalidateField = (id) => {
    const field = $(`#${id}`)

    if (field.attr('type') === 'checkbox') {
        field.closest('label').removeClass('error')
    } else {
        field.removeClass('error')
        $(`#${id}-error`).remove()
    }
}

window.invalidateForm = (id) => {
    $(`#${id}`).find('.error').each((key, element) => invalidateField($(element).attr('id')));
}

$(() => {
    $(document).on('change', '.error', e => {
        invalidateField($(e.target).attr('id'))
    })

    $(document).on('focus', '.error', e => {
        invalidateField($(e.target).attr('id'))
    })
});
